import { onMounted, onBeforeUnmount, watch, isRef, unref, type Ref } from "vue";

export default function useEventListener(
	target: Ref<EventTarget | null> | EventTarget | string,
	event: string,
	handler: (e: Event) => unknown
): void {
	// 如果目标是字符串则解析成全局对象，否则返回其输入值或其解引用值
	const resolveTarget = (t: typeof target) => {
		if (typeof t === "string") {
			return t === "window" ? window : null;
		}

		return isRef(t) ? unref(t) : t;
	};

	const attachListener = (el: EventTarget | null) => {
		if (el) el.addEventListener(event, handler);
	};

	const removeListener = (el: EventTarget | null) => {
		if (el) el.removeEventListener(event, handler);
	};

	// 如果目标是Ref，则当其值变化时更新监听器
	if (isRef(target)) {
		watch(
			target,
			(newEl, prevEl) => {
				removeListener(prevEl as EventTarget);
				attachListener(newEl);
			},
			{ immediate: true }
		); // 立即执行以确保初次运行时监听器被添加
	} else {
		// 其他情况，只需在组件挂载后添加或卸载前移除监听器
		onMounted(() => {
			const resolvedTarget = resolveTarget(target);
			attachListener(resolvedTarget);
		});

		onBeforeUnmount(() => {
			const resolvedTarget = resolveTarget(target);
			removeListener(resolvedTarget);
		});
	}
}
